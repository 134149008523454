import { LiquidButton } from 'components/LiquidButton';
import { FC } from 'react';
import { Text } from 'components/Typography';
import { ICarouselData } from '../types';

interface CardCarouselBannerProps {
  carouselData: ICarouselData;
  searchLink?: string;
  btnHex: string;
  onButtonClick: () => void;
}

export const CardCarouselBanner: FC<CardCarouselBannerProps> = ({
  carouselData,
  searchLink,
  btnHex,
  onButtonClick,
}) => {
  if (!carouselData) return null;

  return (
    <Text className="-mt-8 md:mt-0 text-center flex justify-center" tag="div" type="lead">
      <a
        href={`${
          carouselData.genericCardCarouselCta?.url
            ? carouselData.genericCardCarouselCta.url
            : searchLink
        }`}
        target="_self"
        rel="noreferrer"
        onClick={onButtonClick}
      >
        <span className="sr-only">{carouselData.genericCardCarouselCta?.displayName}</span>
        <LiquidButton
          text={carouselData.genericCardCarouselCta?.displayName || ''}
          height={50}
          width={200}
          color={btnHex}
          textColor={carouselData?.buttonColor === 'Light' ? '#000' : '#FFF'}
        />
      </a>
    </Text>
  );
};
