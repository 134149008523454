import { MetaBallContainer } from 'components/MetaBallContainer';
import { CardCarouselBackgroundImage } from './CardCarouselBackgroundImage';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface CardCaruselLayoutProps {
  children: ReactNode;
  objectImageTop?: string;
  objectImageBottom?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  componentName: string;
  backgroundImage?: string;
}
export const CardCarouselLayout: FC<CardCaruselLayoutProps> = ({
  children,
  objectImageTop,
  objectImageBottom,
  paddingTop,
  paddingBottom,
  componentName,
  backgroundImage,
}) => {
  return (
    <div className={clsx({ 'md:mt-0 -mt-12': objectImageTop })}>
      <MetaBallContainer topRightImage={objectImageTop} bottomLeftImage={objectImageBottom}>
        <div
          className={clsx(
            'h-fit w-full bg-cover overflow-hidden items-center max-w-none bg-center',
            {
              'pt-10 md:pt-40': objectImageTop,
              'pt-0': !objectImageTop,
              'mb-28 md:mb-40': objectImageBottom,
            },
          )}
        >
          <CardCarouselBackgroundImage
            componentName={componentName}
            backgroundImage={backgroundImage}
          />
          <div
            className={clsx('pl-4 lg:pl-10', {
              'pt-14 md:pt-28 2xl:pt-40': paddingTop,
              'pt-10': !paddingTop,
              'pb-14 md:pb-32 2xl:pb-40': paddingBottom,
            })}
          >
            {children}
          </div>
        </div>
      </MetaBallContainer>
    </div>
  );
};
