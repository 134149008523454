import { FC } from 'react';
import { CardType, CardVariation, ISuggestedData } from 'components/Cards/types';
import { Card } from 'components/Cards';

interface CardCarouselVariantsProps {
  item: ISuggestedData;
  height: string;
  readMoreCopy: string;
  trackingEvents?: {
    clickCard: (data: ISuggestedData, position: number | undefined) => void;
    clickCTA: () => void;
  };
  isOdd?: boolean;
  index: number;
  copyDictionary?: Record<string, string>;
  showInfo?: boolean;
  cardVariation: CardVariation;
  cardType?: string;
  textAlign?: string;
}
export const CardCarouselVariants: FC<CardCarouselVariantsProps> = ({
  item,
  height,
  readMoreCopy,
  trackingEvents,
  isOdd,
  index,
  copyDictionary,
  showInfo,
  cardVariation,
  cardType,
  textAlign,
}) => {
  return (
    <div className="mr-5 flex-1 min-w-[77%] md:min-w-[40%] lg:min-w-[28%]">
      {item.__typename === 'GenericPage' ? (
        <Card
          data={item}
          link={{
            url: `${item.slug}`,
            displayName: readMoreCopy,
            openInNewTab: item.link?.openInNewTab ?? false,
          }}
          height={height}
          cardVariation="article"
          trackingEvent={trackingEvents?.clickCard && trackingEvents.clickCard}
          position={index + 1}
          isOdd={isOdd}
        />
      ) : (
        <Card
          data={item}
          copyDictionary={copyDictionary}
          showInfo={showInfo}
          video={!!item.video}
          cardVariation={cardVariation}
          link={{
            url: item.link?.url || '',
            displayName: readMoreCopy,
            openInNewTab: item.link?.openInNewTab ?? false,
          }}
          cardType={(cardType as CardType) || 'medium'}
          height={height}
          textAlign={textAlign || 'text-center'}
          position={index + 1}
          trackingEvent={trackingEvents?.clickCard && trackingEvents.clickCard}
          isOdd={isOdd}
        />
      )}
    </div>
  );
};
