import clsx from 'clsx';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { Text, Title } from 'components/Typography';
import { FC } from 'react';

interface CardCarouselHeaderProps {
  heading: string;
  subHeading: string;
}

export const CardCarouselHeader: FC<CardCarouselHeaderProps> = ({ heading, subHeading }) => {
  const { theme } = useGlobalContext();

  return (
    <div className="text-center md:mb-30">
      <Text tag="p" type="lead" className={clsx(theme ? 'text-secondary !text-lg' : '', 'mb-2.5')}>
        {heading}
      </Text>
      <Title tag="h2" type={theme ? 'lg' : 'md'} className="mb-5">
        {subHeading}
      </Title>
    </div>
  );
};
