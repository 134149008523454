import { LogService } from 'service/log/logService';
import getSearchClient from '../client';
import { Environment } from 'config/environment';

export interface IExtensionDetails {
  variation: string;
  extensionKey: string;
}
/**
 * Maps Algolia indecies variations to search page categories filter names.
 * helps in building the search link, and setting the correct category e.g. recipes
 */
const variationMap: { [name: string]: string } = {
  all: 'all',
  products: 'products',
  tips_and_tricks: 'tips',
  recipe: 'recipes',
  articles: 'articles',
};

/**
 * Maps GraphCMS types to Algolia variations
 */
export const ALGOLIA_SEARCH_INDEX_MAP: Record<string, IExtensionDetails> = {
  recipe: {
    variation: 'recipe',
    extensionKey: 'extension',
  },
  // fallback for object card variation
  object: {
    variation: 'recipe',
    extensionKey: 'extension',
  },
  tipsAndTricks: {
    variation: 'tips_and_tricks',
    extensionKey: 'tipsAndTricks',
  },
  article: {
    variation: 'article',
    extensionKey: 'article',
  },
  product: {
    variation: 'products',
    extensionKey: 'products',
  },
};

/**
 * function to build a search page link from Algolia Extension RefinementList
 * @param refinementList Algolia Extension Refinement list returned from GraphCMS.
 * @param refinementType variation of the Algolia Extension e.g. recipes, Tips and Tricks.
 * @returns search page link with query built from the refinement list
 */
export const buildSearchLink = (
  refinementList: { [name: string]: string | string[] },
  refinementType: string,
): string => {
  let filterQuery = `category=${variationMap[refinementType]}`;
  const refinementKeys = Object.keys(refinementList);
  refinementKeys.forEach((key: string) => {
    if (refinementList[key] instanceof Array) {
      filterQuery += '&filters=';
      const filterAttributes = encodeURIComponent(
        (refinementList[key] as string[])
          .map((refinement: string) => `${key}:${refinement}`)
          .join(','),
      );
      filterQuery += filterAttributes;
    }
  });
  return filterQuery;
};

/**
 * function to build and format a valid Algolia query string from a refinement list.
 * @param refinementList Algolia Extension Refinement list returned from GraphCMS.
 * @returns formulated Algolia query string
 */
export const buildAlgoliaQuery = (refinementList: {
  [name: string]: string | string[];
}): string => {
  let queryString = '';
  const refinementKeys = Object.keys(refinementList ? refinementList : {});
  refinementKeys.forEach((key: string, keyIndex: number) => {
    if (refinementList[key] instanceof Array) {
      const and = `${keyIndex !== 0 && !!queryString ? ' AND ' : ''}`;
      const withParentheses = refinementList[key].length > 1;
      const attributes = (refinementList[key] as string[])
        .map(
          (refinement: string, refinementIndex: number) =>
            `${withParentheses && refinementIndex !== 0 ? `OR ${key}:` : ''}"${refinement}"`,
        )
        .join(' ');
      queryString += `${and}${withParentheses ? '(' : ''}${key}:${attributes}${
        withParentheses ? ')' : ''
      }`;
    }
  });
  return queryString;
};

/**
 * Gets the number of records in Algolia that match the provided query
 * @param query query object or refinement list that is used to filter records
 * @param index the current Algolia index to query against
 * @returns A promise with the number of hits that match the query
 */
const getNumberOfHits = async (query: string, market: string): Promise<number> => {
  const env = Environment.getRuntimeEnvVars().RECIPE_ENV;

  const index = getSearchClient().initIndex(`${env}_recipe_${market}`);
  try {
    const response = await index.search('', {
      filters: query,
      page: 0,
      hitsPerPage: 8,
    });
    // eslint-disable-next-line no-param-reassign
    return response.nbHits;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch recipes', err);
  }
  return 0;
};

export const getNumberOfHitsFromAlgolia = async (
  query: string,
  market: string,
): Promise<number> => {
  return await LogService.logDuration(
    'AlgoliaClient',
    'GetNumberOfHits',
    getNumberOfHits(query, market),
  );
};
