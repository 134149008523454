import { FC } from 'react';
import { CardCarouselBackgroundMask } from './CardCarouselBackgroundMask';

interface CardCarouselBackgroundImageProps {
  componentName: string;
  backgroundImage?: string;
}

export const CardCarouselBackgroundImage: FC<CardCarouselBackgroundImageProps> = ({
  componentName,
  backgroundImage,
}) => {
  if (!backgroundImage) return null;

  return (
    <div className="absolute w-full h-full md:h-85pc overflow-hidden">
      <div className="h-full overflow-hidden w-[140%] ml-[-20%]">
        <CardCarouselBackgroundMask
          componentName={componentName}
          backgroundImage={backgroundImage}
        />
      </div>
    </div>
  );
};
