import { COLORS } from 'styles/constants';
import { CardCarouselContent } from './components/CardCarouselContent';
import { CardCarouselHeader } from './components/CardCarouselHeader';
import { CardCarouselLayout } from './components/CardCarouselLayout';
import type { ICardCarousel } from './types';

export const CardCarousel = ({
  carouselData,
  cardData,
  copyDictionary,
  showInfo,
  cardType,
  height = '500px',
  textAlign,
  searchLink,
  id,
  ctaColor,
  trackingEvents,
  isOdd,
}: ICardCarousel) => {
  // eslint-disable-next-line no-param-reassign
  id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-card-carousel`;
  const btnHex =
    ctaColor || carouselData?.buttonColor === 'Light' ? COLORS.secondary : COLORS.primary;
  const backgroundImage = carouselData?.componentBackground?.[0]?.asset?.[0]?.url || '';
  const objectImageTop = carouselData?.floatingObject?.[0]?.asset?.[0]?.url || '';
  const objectImageBottom = carouselData?.floatingObject?.[1]?.asset?.[0]?.url || '';
  const readMoreCopy = carouselData?.readMoreText?.value || 'Read more';

  const onButtonClick = () => {
    trackingEvents?.clickCTA && trackingEvents.clickCTA();
  };

  if (!carouselData) return null;

  return (
    <CardCarouselLayout
      objectImageTop={objectImageTop}
      objectImageBottom={objectImageBottom}
      componentName={componentName}
      backgroundImage={backgroundImage}
      paddingTop={!carouselData.heading && !!objectImageTop}
      paddingBottom={!carouselData.genericCardCarouselCta && !!objectImageBottom}
    >
      <CardCarouselHeader heading={carouselData.subHeading} subHeading={carouselData.heading} />
      <CardCarouselContent
        carouselData={carouselData}
        cardData={cardData}
        height={height}
        readMoreCopy={readMoreCopy}
        trackingEvents={trackingEvents}
        isOdd={isOdd}
        copyDictionary={copyDictionary}
        showInfo={showInfo}
        cardType={cardType}
        textAlign={textAlign}
        searchLink={searchLink}
        btnHex={btnHex}
        onButtonClick={onButtonClick}
      />
    </CardCarouselLayout>
  );
};
