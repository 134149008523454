import { CardCarouselBanner } from './CardCarouselBanner';
import { CardCarouselVariants } from './CardCarouselVariants';
import { CardVariation, ISuggestedData } from 'components/Cards/types';
import { FC } from 'react';
import { ICarouselData } from '../types';
import { Slider } from '@/components/SliderVanilla';

interface CardCarouselContentProps {
  carouselData: ICarouselData;
  cardData: ISuggestedData[] | undefined;
  height: string;
  readMoreCopy: string;
  trackingEvents?: {
    clickCard: (data: ISuggestedData, position: number | undefined) => void;
    clickCTA: () => void;
  };
  isOdd?: boolean;
  copyDictionary?: Record<string, string>;
  showInfo?: boolean;
  cardType?: string;
  textAlign?: string;
  searchLink?: string;
  btnHex: string;
  onButtonClick: () => void;
}

export const CardCarouselContent: FC<CardCarouselContentProps> = ({
  carouselData,
  cardData,
  height,
  readMoreCopy,
  trackingEvents,
  isOdd,
  copyDictionary,
  showInfo,
  cardType,
  textAlign,
  searchLink,
  btnHex,
  onButtonClick,
}) => {
  return (
    <>
      <Slider>
        {cardData?.map((item: ISuggestedData, index: number) => (
          <CardCarouselVariants
            key={`card-data-${item.id ?? index}`}
            item={item}
            height={height}
            readMoreCopy={readMoreCopy}
            trackingEvents={trackingEvents}
            isOdd={isOdd}
            index={index}
            copyDictionary={copyDictionary}
            showInfo={showInfo}
            cardVariation={carouselData.cardVariation as CardVariation}
            cardType={cardType}
            textAlign={textAlign}
          />
        ))}
      </Slider>
      {carouselData?.genericCardCarouselCta?.displayName &&
        carouselData?.cardVariation !== 'product' && (
          <CardCarouselBanner
            carouselData={carouselData}
            searchLink={searchLink}
            btnHex={btnHex}
            onButtonClick={onButtonClick}
          />
        )}
    </>
  );
};
