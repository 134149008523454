import { Environment } from '@/config/environment';
import { ILogObj, Logger } from 'tslog';

type Source = 'RecipeClient' | 'AlgoliaClient' | 'HyGraphClient' | 'Redis' | 'Redirects';

const Colors: Record<Source, string> = {
  RecipeClient: '\x1b[35m',
  AlgoliaClient: '\x1b[36m',
  HyGraphClient: '\x1b[34m',
  Redis: '\x1b[33m',
  Redirects: '\x1b[90m',
};

const log: Logger<ILogObj> = new Logger({
  minLevel: 3,
});

const { VERBOSE_LOGGING } = Environment.getRuntimeEnvVars();

const logDuration = (source: Source, funcName: string, promise: Promise<any>) => {
  const random = Math.random().toString(36).substr(2, 5);
  const sourceFormatted = source.padEnd(14, ' ');
  const funcNameFormatted = funcName.padEnd(25, ' ');
  const start = Date.now();
  return promise
    .then((response) => {
      const difference = Date.now() - start;
      const timeColor =
        difference < 5
          ? '\x1b[34m'
          : difference < 50
            ? '\x1b[0m'
            : difference < 100
              ? '\x1b[33m'
              : '\x1b[31m';

      if (VERBOSE_LOGGING) {
        console.log(
          `${random} ${
            Colors[source]
          } ${sourceFormatted} \x1b[32m ${funcNameFormatted} ${timeColor} ${
            difference + 'ms'
          }\x1b[0m`,
        );
      }

      return response;
    })
    .catch((error) => {
      console.log(
        `${random} ${Colors[source]} ${sourceFormatted} \x1b[31m ${funcNameFormatted} ${error.message}\x1b[0m`,
      );
      throw error;
    });
};

const logMessage = (source: Source, message: string) => {
  const random = Math.random().toString(36).substr(2, 5);
  const sourceFormatted = source.padEnd(14, ' ');
  if (VERBOSE_LOGGING) {
    console.log(`${random} ${Colors[source]} ${sourceFormatted} \x1b[32m ${message}\x1b[0m`);
  }
};

const logError = (source: Source, message: string) => {
  const random = Math.random().toString(36).substr(2, 5);
  const sourceFormatted = source.padEnd(14, ' ');
  console.log(`${random} ${Colors[source]} ${sourceFormatted} \x1b[31m ${message}\x1b[0m`);
};

const logPageVisit = (page: string) => {
  if (page.indexOf('robots.txt') < 0) {
    console.log('\x1b[1m' + ''.padEnd(62, '-') + '\x1b[0m');
    console.log(''.padEnd((60 - page.length) / 2, ' ') + '\x1b[1m' + page + '\x1b[0m');
    console.log('\x1b[1m' + ''.padEnd(62, '-') + '\x1b[0m');
  }
};

export const LogService = {
  logPageVisit,
  logError,
  logDuration,
  logMessage,
  log,
};
